import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationParams } from 'models/Application';

const initialState: ApplicationParams = {
  includeDeletedData: undefined,
  includeInactiveData: undefined,
  returnDeletedDataOnly: undefined,
  orderByDirection: undefined,
  orderByField: undefined,
  applicationNameContains: undefined
};

export const freddyCadabbyParams = createSlice({
  name: 'freddyCadabbyParams',
  initialState,
  reducers: {
    setIncludeDeletedData: (state, { payload }: PayloadAction<ApplicationParams['includeDeletedData']>): void => {
      state.includeDeletedData = payload;
    },
    setIncludeInactiveData: (state, { payload }: PayloadAction<ApplicationParams['includeInactiveData']>): void => {
      state.includeInactiveData = payload;
    },
    setReturnDeletedDataOnly: (state, { payload }: PayloadAction<ApplicationParams['returnDeletedDataOnly']>): void => {
      state.returnDeletedDataOnly = payload;
    },
    setOrderByDirection: (state, { payload }: PayloadAction<ApplicationParams['orderByDirection']>): void => {
      state.orderByDirection = payload;
    },
    setOrderByField: (state, { payload }: PayloadAction<ApplicationParams['orderByField']>): void => {
      state.orderByField = payload;
    },
    setApplicationNameContains: (state, { payload }: PayloadAction<ApplicationParams['applicationNameContains']>): void => {
      state.applicationNameContains = payload;
    }
  }
});

export const { setIncludeDeletedData, setIncludeInactiveData, setOrderByDirection, setApplicationNameContains, setReturnDeletedDataOnly, setOrderByField } = freddyCadabbyParams.actions;

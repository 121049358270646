import { EditFilled, SaveOutlined } from '@ant-design/icons';
import { Avatar, Col, message, Modal, Row, Spin, Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { AntButton } from 'components/UI/AntButton';
import { InlineStylesModel } from 'models/InlineStylesModel';
import React, { useCallback, useEffect, useState } from 'react';
import Cropper, { Area, Point } from 'react-easy-crop';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from 'redux/app/app-slice';
import { RootState } from 'redux/rootReducer';
import { cookieMonsterApi, useGetUserQuery, useUpdateUserProfileImageMutation } from 'redux/services/cookieMonster/cookieMonsterApi';
import { getCroppedImg } from 'utils/canvasUtils';

const styles: InlineStylesModel = {
  cropContainer: {
    height: '60vh'
  },
  controls: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    width: '100%',
    transform: 'translateX(-50%)',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingLeft: 10
  },
  title: {
    position: 'absolute',
    top: 0,
    left: '50%',
    width: '100%',
    transform: 'translateX(-50%)',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    zIndex: 1,
    justifyContent: 'center'
  }
};

export const LogoUpload: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.app);

  const userId = user?.sub || '';
  const { data: userData, isLoading, isFetching } = useGetUserQuery(userId as string, { skip: !userId });
  const [test, setTest] = useState<string>(userData ? userData.user_metadata.profile_image_url : '');
  const dispatch = useDispatch();

  const [updateProfileImage, { isLoading: isDeleting }] = useUpdateUserProfileImageMutation();

  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = React.useState('');
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(2);
  const [hover, setHover] = useState(false);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [croppedImage, setCroppedImage] = useState<string>('');
  const userAvatarSrc = user && user['https://acuity.mdsiinc.com/user/user_metadata'].profile_image_url;

  function readFile(file: any) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const handleCroppedImageUpload = useCallback(async () => {
    if (!userId) return message.error('No customer found, please refresh the page');
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);

      croppedImage?.toBlob(async (blob: BlobPart | any) => {
        const fileCropped = new File([blob], `logo${new Date().toISOString()}.jpeg`, { type: 'image/jpeg' });
        const formData = new FormData();

        formData.append('logo.jpeg', fileCropped);

        const response = await updateProfileImage({ userId, payload: formData }).unwrap();

        if (user?.email || user?.email_verified || !user) {
          throw 'user failed';
        }
        dispatch(
          setUser({
            ...user,
            ['https://acuity.mdsiinc.com/user/user_metadata']: { ...user['https://acuity.mdsiinc.com/user/user_metadata'], ['profile_image_url']: response.user_metadata.profile_image_url }
          })
        );
      }, 'image/jpeg');
      message.success('Logo successfully changed');
    } catch (error) {
      message.error('Logo could not be changed at this time');
      console.error(error);
    }
  }, [userId, imageSrc, croppedAreaPixels, updateProfileImage]);

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onOk = () => {
    try {
      setCroppedImage('');
      setImageSrc('');
      setShowModal(false);
      handleCroppedImageUpload();
      dispatch(cookieMonsterApi.util.invalidateTags(['User']));
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = () => {
    const emptyLogo = new FormData();

    emptyLogo.append('logo.jpeg', '');
    updateProfileImage({ userId, payload: emptyLogo });

    if (user?.email || user?.email_verified || !user) {
      return;
    }

    dispatch(
      setUser({
        ...user,
        ['https://acuity.mdsiinc.com/user/user_metadata']: { ...user['https://acuity.mdsiinc.com/user/user_metadata'], ['profile_image_url']: '' }
      })
    );
  };

  // const onClose = useCallback(() => {
  //   setCroppedImage('null');
  // }, []);

  const handleCloseModal = () => {
    setCroppedImage('');
    setImageSrc('');
    setShowModal(false);
  };

  const props: UploadProps = {
    name: 'file',
    async onChange(info: any) {
      setShowModal(true);
      if (info.file) {
        const file = info.file.originFileObj;
        const imageDataUrl = await readFile(file);

        setImageSrc(imageDataUrl as any);
      }
    }
  };

  useEffect(() => {
    if (test === '') {
      setTest(userData?.user_metadata.profile_image_url ?? '');
    }
  }, [userData]);

  return (
    <div style={{ paddingTop: 32 }}>
      {imageSrc ? (
        <React.Fragment>
          <Modal width={800} visible={showModal} onCancel={handleCloseModal} onOk={onOk}>
            <div style={styles.cropContainer}>
              <Cropper
                cropSize={{ width: 512, height: 512 }}
                cropShape="round"
                showGrid={false}
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <div style={styles.controls}>
              <input
                type="range"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => {
                  setZoom(e.target.value as any);
                }}
                className="zoom-range"
              />
            </div>
          </Modal>
        </React.Fragment>
      ) : (
        <>
          <Row align="middle">
            <Upload {...props}>
              <Col style={{ borderRadius: 50 }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <Avatar
                  icon={(isLoading || isFetching) && <Spin spinning></Spin>}
                  style={{ cursor: 'pointer', filter: hover ? 'brightness(50%)' : '', transition: '0.25s ease-in-out', position: 'relative' }}
                  size={100}
                  alt=""
                  src={userAvatarSrc || user?.picture}
                />
                {hover && (
                  <Avatar
                    icon={<EditFilled />}
                    style={{ cursor: 'pointer', filter: hover ? 'brightness(60%)' : '', transition: '0.25s ease-in-out', position: 'absolute', left: 0, opacity: 0.65 }}
                    size={100}
                    alt=""
                  />
                )}
              </Col>
            </Upload>
            <Col>
              <AntButton style={{ marginLeft: 16 }} disabled={!userAvatarSrc} onClick={handleDelete} loading={isDeleting} danger type="ghost" icon={<SaveOutlined />}>
                Delete Current Picture
              </AntButton>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

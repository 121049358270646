/* eslint-disable react-hooks/exhaustive-deps */
// import { useDispatch, useSelector } from 'react-redux';

import { BackTop, Layout, Row, Typography } from 'antd';
import { Loader } from 'components/atoms/Loader';
import { PageHeader } from 'components/organisms/PageHeader';
import { PageSubHeader } from 'components/organisms/PageSubHeader';
import { About } from 'features/about/About';
import { AddRules } from 'features/addRules/AddRules';
import { EditProfilePage } from 'features/editProfile/EditProfile';
import { EditRules } from 'features/editRules/EditRules';
import { Forbidden } from 'features/forbidden/Forbidden';
import { ImportUpdatedRules } from 'features/importUpdatedRules/ImportUpdatedRules';
import { useAuthentication } from 'hooks/useAuthentication';
import Cookies from 'js-cookie';
import { AcuityContextModel } from 'models/AcuityContextModel';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { InlineStylesModel } from '../models/InlineStylesModel';
import { setAcuityContext } from '../redux/app/app-slice';

// import { RootState } from '../redux/rootReducer';
// import { setLoader } from '../redux/app/app-slice';

const { Header, Content } = Layout;
const styles: InlineStylesModel = {
  layout: {
    minHeight: '100vh'
  },
  header: {
    padding: '0 50px',
    width: '100%'
  },
  content: {
    padding: '10px 50px',
    width: '100%',
    maxWidth: 1920,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  somethingWrong: {
    textAlign: 'center',
    height: '100vh'
  }
};

function App(): JSX.Element {
  const location = useLocation();
  const dispatch = useDispatch();
  const { accessToken, isLoading } = useAuthentication();

  const [cookieNotFound, setCookieNotFound] = useState(false);

  // Fetch cookie and set in store
  useEffect(() => {
    const cookie = Cookies.get(`${process.env.REACT_APP_COOKIE_PREFIX}_acuity_context`);
    const cookieJSON: AcuityContextModel = cookie ? JSON.parse(cookie) : undefined;

    if (cookieJSON?.selectedCustomer) {
      dispatch(setAcuityContext(cookieJSON));
    } else {
      setCookieNotFound(true);
    }

    if (cookieNotFound) {
      setTimeout(() => {
        window.location.href = process.env.REACT_APP_ERNIE_REDIRECT_URL;
      }, 5000);
    }
  }, [cookieNotFound, dispatch]);

  if (isLoading || !accessToken) {
    return <Loader />;
  }

  if (cookieNotFound) {
    return (
      <Row style={styles.somethingWrong} justify="center" align="middle">
        <Typography.Title level={4}>Opps something went wrong... Redirecting you back to dashboard... Please try again in a few moments...</Typography.Title>
      </Row>
    );
  }

  return (
    <Layout style={styles.layout}>
      <BackTop />
      <Header style={styles.header}>
        <PageHeader />
      </Header>
      {location.pathname !== '/about' && location.pathname !== '/profile' ? <PageSubHeader /> : null}
      <Content style={styles.content}>
        <Switch>
          <Route path="/" exact component={EditRules} />
          <Route path="/about" component={About} />
          <Route path="/profile/" component={EditProfilePage} />
          <Route path="/add" exact component={AddRules} />
          <Route path="/unauthorized" exact component={Forbidden} />
          <Route path="/importUpdatedRules" exact component={ImportUpdatedRules} />
        </Switch>
      </Content>
    </Layout>
  );
}

export default App;

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { DTOModel } from 'models/DTOModel';

const jamieFoxBaseUrl = process.env.REACT_APP_JAMIE_FOX_BASE_URL;
const jameFoxFunctionKey = process.env.REACT_APP_API_HOST_KEY_JAMIE_FOX;

const getRuleOwners = async (token: string, divisionId: string): Promise<DTOModel<string[] | null>> => {
  const axiosOptions: AxiosRequestConfig = {
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'x-functions-key': jameFoxFunctionKey
    },
    method: 'get',
    //TODO: FIX MDSI HARDCODE
    url: `${jamieFoxBaseUrl}/divisions/${divisionId}/receivingRules/ruleOwners`
  };

  const response: DTOModel<string[] | null> = {
    data: null,
    error: null,
    status: null
  };

  try {
    const resp: AxiosResponse<string[] | null> = await axios(axiosOptions);

    response.data = resp.data;
    response.status = resp.status;
  } catch (err) {
    const axiosError = err as AxiosError;

    response.error = {
      errorMessage: axiosError.response?.data,
      status: axiosError.response?.status
    };
  }

  return response;
};

export const RuleOwnerService = {
  getRuleOwners
};

import React from 'react';
import '../../common/styles/loaderStyle.less';

interface DirectionProps {
  direction: string;
}

export const BlockLoaderSmall = ({ direction }: DirectionProps): JSX.Element => {
  return (
    <div className={direction}>
      <span className="loader-item-small">1</span>
      <span className="loader-item-small">2</span>
      <span className="loader-item-small">3</span>
      <span className="loader-item-small">4</span>
      <span className="loader-item-small">5</span>
      <span className="loader-item-small">6</span>
    </div>
  );
};

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { RootState } from 'redux/rootReducer';

const baseUrl = process.env.REACT_APP_HONKERS_BASE_URL;
const functionsKey = process.env.REACT_APP_API_HOST_KEY_HONKERS;

export const honkersApi = createApi({
  reducerPath: 'honkersApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).app.accessToken;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('x-functions-key', functionsKey);
      }

      return headers;
    }
  }),
  endpoints: (builder) => ({
    getVersion: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/version',
        responseHandler: (response): Promise<string> => response.text()
      })
    }),
    getApiName: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/apiName',
        responseHandler: (response): Promise<string> => response.text()
      })
    })
  })
});

export const { useGetVersionQuery, useGetApiNameQuery } = honkersApi;

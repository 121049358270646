import { InlineStylesModel } from 'models/InlineStylesModel';
import React from 'react';
import { Row } from 'antd';
import { ValidAndUnvalidRulesGrid } from 'components/organisms/ValidAndUnvalidRulesGrid';

const styles: InlineStylesModel = {
  spacing: {
    marginTop: 10
  }
};

export const ImportUpdatedRules = (): JSX.Element => {
  return (
    <>
      <Row style={styles.spacing}>Bulk Import Results</Row>
      <Row style={styles.spacing} align="middle">
        <ValidAndUnvalidRulesGrid fromUpdate={true} />
      </Row>
    </>
  );
};
